<template>
  <commonHeader></commonHeader>
  <div class="wrap">
    <div class="w950 mauto ohidden">
      <div class="fl">
        <img src="~@/assets/images/mobile.png" width="410" alt />
      </div>
      <div class="fr tc" style="padding-top:72px">
        <div>
          <img width="178" src="~@/assets/images/download-logo.png" alt />
        </div>
        <div class="pt30 pb20">
          <img width="173" src="~@/assets/images/download-qrcode.png" alt />
        </div>
        <div>
          <img width="207" src="~@/assets/images/download-txt.png" alt />
        </div>
      </div>
    </div>
  </div>
  <commonFooter></commonFooter>
</template>

<script>
import commonHeader from '../public/header.vue'
import commonFooter from '../public/footer.vue'
export default {
  components: {
    commonHeader,
    commonFooter,
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  padding: 110px 0 62px;
  background:#f9f9f9 url('~@/assets/images/download-bg.jpg') no-repeat 50% -70px;
  .w950{
    padding: 0 154px 0 120px;
  }
}
</style>